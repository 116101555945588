@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,700,900&amp;subset=latin-ext,vietnamese" rel="stylesheet');

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.steps .nav-item a {
    color:  black;
    display: block;
    font-size: 20px;
    padding: 0;
    padding-bottom: 8px;
    margin-right: 24px;
    margin-bottom:  24px;
    text-decoration: none;
}

.steps .nav-item.active a {
    border-bottom: 2px solid #2335b7;
}

.form-group {
    background-color: #e2ecff;
    text-align: left;
    padding: 10px;
    display: block;
    width: 100%;
}

.error .form-group {
    background-color: #ffe2e2;
    border: 2px solid #db0000;
}

.errorMessage {
    color: #db0000;
    background-color: white;
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin-top: -14px;
    margin-bottom: 8px;
}

.error .form-group.checkbox {
    border: 0 solid;
}
.error .form-group.checkbox .errorMessage {
    padding-left: 50px;
}

.form-group label {
    background-color: #e2ecff;
    font-size: 12px;
    font-weight: 400;
    display: block;
}

.error .form-group label {
    background-color: #ffe2e2;
}

.form-group input[type="text"],
.form-group input[type="text"]:focus,
.form-group input[type="password"],
.form-group input[type="password"]:focus,
.form-group select,
.form-group select:focus {
    background-color: #e2ecff;
    color: black;
    font-size: 18px;
    border: none;
    height: 38px;
    width: 100%;
    padding: 0 !important;
}

.form-control::placeholder,
select.empty {
    color: #6c757d;
}

select.empty option {
    color: black;
}

.error .form-group input[type="text"],
.error .form-group input[type="text"]:focus,
.error .form-group input[type="password"],
.error .form-group input[type="password"]:focus,
.error .form-group select,
.error .form-group select:focus {
    background-color: #ffe2e2;
    color: black;
}

.form-group.checkbox {
    padding: 0;
    background-color: #ffffff;
}

.form-group.checkbox div {
    display: flex;
}

.form-group.checkbox label {
    font-size: 14px;
    padding-top: 0;
    min-height: 28px;
    padding-left: 50px;
    display: inline-block;
    background-image: url('/checkbox-not-checked.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #ffffff;
}

.form-group.checkbox input:checked ~ label {
    background-image: url('/checkbox-checked.svg');
}

.form-group.checkbox input:disabled ~ label {
    background-image: url('/checkbox-disabled.svg');
}

h3,
h3 .form-group.checkbox label {
    color: #2335b7;
    font-size: 20px;
}

h3 p,
h3 + p {
    color: black;
    padding-left: 50px;
}

h3.noCheckbox,
h3 .form-group.checkbox label {
    padding-left: 50px;
}

h3 + p.noPadding {
    padding-left: 0;
}


.form-group.checkbox label ~ div {
    margin-top: 0;
}

.form-group.checkbox input {
    display: none;
}

.appContent .formSection {
    box-shadow: 0 4px 64px -18px lightgray;
    margin: 32px 0;
    padding: 32px;
}

.appContent .additionalServices h3 {
    color: #38a967;
    font-weight: bold;
}

.appContent .additionalServices .formSection {
    box-shadow: 0 4px 64px -18px #38a967;
}

.additionalServices h3 .form-group.checkbox label {
    color: #38a967;
}

.additionalServices p {
    font-weight: normal;
}

.subForm {
    padding-left: 50px;
}

.btn {
    white-space: nowrap;
    border-radius: 0;
    font-size: 14px;
}

form .btn {
    width: 100%;
    max-width: 400px;
    margin-bottom: 12px;
    box-shadow: 0 10px 30px rgba(1, 135, 253, 0.32);
    font-weight: 700;
    font-size: 16px;
    padding: 30px 50px;
    text-transform: uppercase;
}

form .btn.download {
    width: auto;
    max-width: none;
    box-shadow: none;
    font-weight: normal;
    text-transform: none;
    padding: 6px 12px;
}

.btn-primary {
    background-color: #0187fd;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #006cca;
}

.Toastify__toast--error {
    background-color: #ff8282;
    color: #ffffff;
}

.appContent {
    padding: 2rem;
}

.appContent h1 {
    font-size: 32px;
    margin-bottom: 16px;
}

.appContent p {
    font-size: 14px;
    margin-bottom: 14px;
}

.appContent .card {
    align-items: center;
    justify-content: center;
    padding: 36px 0 0;
    border: 0;
    box-shadow: 0 4px 64px -18px lightgray;
}

.appContent .card-body {
    padding-left: 0;
    padding-right: 0;
}

.appContent .card-title {
    font-size: 1rem;
}

.appContent .card-subtitle {
    color: #777;
    font-size: 0.9rem;
    margin-bottom: 7px;
}

.appContent .card-img-top {
    width: 30%;
    cursor: pointer;
}

.appContent .card .btn {
    display: inline-block;
    margin-top: 12px;
    margin-right: 12px;
}

.popover {
    max-width: 552px;
}

.popover-body {
    color: white;
    background-color: #565656;
}

.popover-body a {
    color: #56d1ff;
}

.popover-body a:hover {
    color: #68e3ff;
}

.cleared {
    display: block;
    clear: both;
}

.important {
    color: red;
    font-size: 18px;
}

.navButtons {
    float: right;
    margin-top: 5px;
    margin-bottom: 5px;
}

.navButtons .btn {
    margin-right: 10px;
    height: 40px;
}

.formSection.summary .row {
    margin-bottom: 12px;
}

.formSection.summary .summary-block {
    margin-bottom: 24px;
}

.row.add-activity {
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .appContent {
        padding: 2rem;
    }

    .appContent h1 {
        font-size: 24px;
        margin-bottom: 12px;
    }

    h3,
    h3 .form-group.checkbox label {
        font-size: 16px;
    }

    .appContent p {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .appContent .formSection {
        box-shadow: 0 4px 64px -18px lightgray;
        margin: 16px 0;
        padding: 16px;
    }

    .subForm {
        padding-left: 0;
    }
}

@media only screen and (max-width: 490px) {
    .appContent {
        padding: 1rem;
    }

    .appContent h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }
}
